import React from 'react';

class SearchResults extends React.Component {
  render() {
    const listItems = this.props.songs.map((song) =>
      <div key={song.id} className="flex bg-gray-400 w-screen px-4 py-2">
        <div className="flex-grow">
          <div>
            {song.name}
          </div>
          <div>
            {song.artist}
          </div>
        </div>
        <div className="flex-grow text-right">
          <button
            songId={song.id}
            onClick={() => this.props.addTrack(song.id)}
            className='bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded'>
            +
          </button>
        </div>
      </div>
    );

    return (
      <div className='SearchResults bg-gray-100'>
        {listItems}
      </div>
    )
  }
}

export default SearchResults;
