import React from 'react';
import './main.css';

import FetchHelper from '../module/FetchHelper'

import SearchResults from './SearchResults';
import Queue from './Queue';

import {DebounceInput} from 'react-debounce-input';

function MainException(message, ignore, object) {
  this.message = message;
  this.ignore = ignore;
  this.object = object;

  if (!ignore) {
    console.log("MainException", object)
  }

  this.name = 'MainException';
}

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      songs: [],
      searchTerm: '',
      foundSongs: [],
      queueIsLoaded: false,
      queue: [],
    };

    this.handleChangeSearchTerm = this.handleChangeSearchTerm.bind(this);
  }

  handleChangeSearchTerm(event) {
    this.setState({searchTerm: event.target.value});
    this.search();
  }

  search() {
    var str = this.state.searchTerm.replace(/(?:^\s*|\s*$)/g, '').toLowerCase();
    var found = [];
    var reg = new RegExp(str.split(' ').join('.*'), 'g');
    var list = this.state.songs;
    var i = list.length;
    while(i--){
      if(reg.test(list[i].searchTerm)) found.push(list[i]);
    }
    this.setState({
      foundSongs: found
    })
  }

  componentDidMount() {
    this.loadSongs();
    this.loadQueue();
  }

  loadSongs() {
    FetchHelper("/api/v1/songs")
    .then(
      res => {
        if (res.ok) {
          return res.json()
        }

        throw new MainException('Oops we hit an error', false, res)
      }
    )
    .then(
      (result) => {
        if (result.error) {
          throw new MainException(result.error, false, result);
        }

        let songs = result || []
        songs.forEach(function(song, index) {
          this[index] = {
            ...song,
            searchTerm: (song.artist + song.name + song.artist).toLowerCase(),
          };
        }, songs)

        this.setState({
          isLoaded: true,
          songs: songs,
        });
      },
      (error) => {
        if (!error.ignore) {
          this.setState({
            isLoaded: true,
            error
          });
        }
      }
    )
  }

  loadQueue() {
    FetchHelper("/api/v1/playlist")
    .then(
      res => {
        if (res.ok) {
          return res.json()
        }

        throw new MainException('Oops we hit an error', false, res)
      }
    )
    .then(
      (result) => {
        if (result.error) {
          throw new MainException(result.error, false, result);
        }

        this.setState({
          queueIsLoaded: true,
          queue: result.tracks,
        });
      },
      (error) => {
        if (!error.ignore) {
          this.setState({
            queueIsLoaded: true,
            error
          });
        }
      }
    )
  }

  addTrack = (songId) => {
    let data = {
      song_id: songId,
    }
    FetchHelper(
      "/api/v1/tracks",
      {method: 'POST', body: JSON.stringify(data) }
    ).then(res => res.json())
    .then(
      (result) => {
        console.log(result);
        this.loadQueue();
      },
      (error) => {
        if (!error.ignore) {
          this.setState({
            error
          });
        }
      })
  }

  skipTrack = () => {
    let data = {}
    FetchHelper(
      "/api/v1/tracks/skip",
      {method: 'POST', body: JSON.stringify(data) }
    ).then(res => res.json())
    .then(
      (result) => {
        console.log(result);
        this.loadQueue();
      },
      (error) => {
        if (!error.ignore) {
          this.setState({
            error
          });
        }
      })
  }

  playQueue = () => {
    let data = {}
    FetchHelper(
      "/api/v1/tracks/play",
      {method: 'POST', body: JSON.stringify(data) }
    ).then(res => res.json())
    .then(
      (result) => {
        console.log(result);
        this.loadQueue();
      },
      (error) => {
        if (!error.ignore) {
          this.setState({
            error
          });
        }
      })
  }

  pauseQueue = () => {
    let data = {}
    FetchHelper(
      "/api/v1/tracks/pause",
      {method: 'POST', body: JSON.stringify(data) }
    ).then(res => res.json())
    .then(
      (result) => {
        console.log(result);
        this.loadQueue();
      },
      (error) => {
        if (!error.ignore) {
          this.setState({
            error
          });
        }
      })
  }

  focusOnSearch = () => { this.searchInput.focus(); }
  clearSearchTerm = () => { this.setState({searchTerm: ''}); }

  render() {
    let content
    if (this.state.searchTerm !== '') {
      content = (<SearchResults songs={this.state.foundSongs} addTrack={this.addTrack} />)
    } else {
      content = (
        <Queue
          tracks={this.state.queue}
          logout={this.props.logout}
          play={this.playQueue}
          pause={this.pauseQueue}
          skip={this.skipTrack}
        />
      )
    }

    return (
      <div className='Main bg-gray-100'>
        <header className='Header'>
          <div className="flex bg-gray-200 w-screen">
            <div className="flex-none text-gray-800 text-center bg-gray-400">
              <button
                className='h-full px-1'
                onClick={this.focusOnSearch}
              >
                <i className="fas fa-search"></i>
              </button>
            </div>
            <div className="flex-grow text-gray-800 text-center bg-gray-400">
              <DebounceInput
                inputRef={(input) => { this.searchInput = input; }}
                className='SearchInput pl-4 bg-gray-400 w-full'
                value={this.state.searchTerm}
                debounceTimeout={300}
                onChange={this.handleChangeSearchTerm}
              />
            </div>
            <div className="flex-none text-gray-800 text-center bg-gray-400">
              {this.state.searchTerm === '' ? null : (
                <button
                  className="h-full p-2"
                  onClick={this.clearSearchTerm}
                >
                  <i className="far fa-times-circle"></i>
                </button>
              )}
            </div>
          </div>
          {this.state.isLoaded ? null :
              <div className='LoadingBar pl-4'>Loading songs</div>
          }
        </header>
        {content}
      </div>
    )
  }
}

export default Main;
