import React from 'react';
import Login from './login/Login';
import Main from './main/Main';
import FetchHelper from './module/FetchHelper'

function AppException(message, ignore, object) {
  this.message = message;
  this.ignore = ignore;
  this.object = object;

  if (!ignore) {
    console.log("AppException", object)
  }

  this.name = 'AppException';
}

const localStorageKey = 'mathisToken'

class App extends React.Component {
  constructor(props) {
    super(props);
    var currentToken = localStorage.getItem(localStorageKey);

    this.state = {
      error: null,
      isLoaded: false,
      user: null,
      token: currentToken,
    };
  }

  componentDidMount() {
    FetchHelper(
      "/api/v1/whoami",
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.state.token,
        },
      }
    )
    .then(
      res => {
        if (res.ok) {
          return res.json()
        }

        if (res.status === 403) {
          this.setState({
            isLoaded: true
          });

          throw new AppException('not logged in', true)
        }

        throw new AppException('Oops we hit an error', false, res)
      }
    )
    .then(
      (result) => {
        if (result.username) {
          this.setState({
            isLoaded: true,
            user: result,
          });

          return;
        }

        this.setState({isLoaded: true});

        throw new AppException('Unknown Error', false, result);
      },
      (error) => {
        if (!error.ignore) {
          this.setState({
            isLoaded: true,
            error
          });
        }
      }
    )
  }

  setUser = (user) => {
    this.setState({
      token: user.token,
      user
    });
    localStorage.setItem(localStorageKey, user.token);
  }

  logout = () => {
    this.setState({
      token: undefined,
      user: undefined,
    });
    localStorage.removeItem(localStorageKey);
  }

  render() {
    const { error, isLoaded, user } = this.state;
    if (error) {
      return (
        <div
          className="m-4 text-red-700 bg-red-100 border border-solid border-red-600 rounded-lg py-2 px-4 block">
          Error: {error.message}
        </div>
      );
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else if (user)  {
      return <Main user={user} logout={this.logout} />;
    } else {
      return <Login setUser={this.setUser}/>;
    }
  }
}

export default App;
