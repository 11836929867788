import React from 'react';
import './Login.css';

import FetchHelper from '../module/FetchHelper'

const localStorageKey = 'mathisToken'

function LoginException(message, ignore, object) {
  this.message = message;
  this.ignore = ignore;
  this.object = object;

  if (!ignore) {
    console.log("LoginException", object)
  }

  this.name = 'LoginException';
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      user: null,
      currentCode: undefined,
      currentName: undefined,
    };

    this.handleChangeCode = this.handleChangeCode.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeCode(event) {
    this.setState({currentCode: event.target.value});
  }

  handleChangeName(event) {
    this.setState({currentName: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
    this.login();
  }

  login() {
    this.setState({
      error: undefined,
    })

    let data = {
      session_key: this.state.currentCode,
      name: this.state.currentName,
    }

    FetchHelper(
      "/api/v1/user/signin",
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }
    )
      .then(res => res.json())
      .then(
        (result) => {
          if (result.error) {
            this.setState({
              error: new LoginException(result.error, false, result)
            })
            return
          }

          if (result.token) {
            localStorage.setItem(localStorageKey, result.token);
            this.props.setUser(result)
            return
          }

          this.setState({
            error: new LoginException("Error logging in", false, result)
          })
        },
        (error) => {
          if (!error.ignore) {
            this.setState({
              isLoaded: true,
              error
            });
          }
        }
      )
  }

  render() {
    let errorEl;

    if (this.state.error) {
      errorEl = <div className="m-4 text-red-700 bg-red-100 border border-solid border-red-600 w-full rounded-lg py-2 px-4 block">
        {this.state.error.message}
      </div>
    }

    return (<div className="flex flex-col w-3/4 mx-auto my-12 items-center">
      <div className="Brand m-4 w-full">
        &nbsp;
      </div>

      <form onSubmit={this.handleSubmit} className="m-4 w-full items-center">
        <input
          className="LoginCode mb-4 bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
          type="text"
          placeholder="Screen Code"
          value={this.state.currentCode}
          onChange={this.handleChangeCode}
        />

        <input
          className="LoginName bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
          type="text"
          placeholder="Your Name"
          value={this.state.currentName}
          onChange={this.handleChangeName}
        />

        <button className="LoginButton m-4 bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
        Enter &#8227;
        </button>
      </form>

      {errorEl}
    </div>);
  }
}

export default Login;
