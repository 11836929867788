import React from 'react';

class Controls extends React.Component {
  render() {
    const noTracks = !this.props.queueState

    return (
      <div className="inline-flex">
        <button
          className="bg-blue-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
          onClick={this.props.logout}
        >
          Logout
        </button>
        {this.props.queueState === 'paused' ?
            (
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
                disabled={noTracks}
                onClick={this.props.play}
              >
                <i className="fas fa-play"></i>
              </button>
            ) :
            (
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
                disabled={noTracks}
                onClick={this.props.pause}
              >
                <i className="fas fa-pause"></i>
              </button>
            )
        }
        <button
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r"
          disabled={noTracks}
          onClick={this.props.skip}
        >
          <i className="fas fa-fast-forward"></i>
        </button>
      </div>
    )
  }
}

export default Controls;
