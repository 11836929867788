import React from 'react';

import Controls from './Controls';

class Queue extends React.Component {
  queueState() {
    let {tracks} = this.props;
    let currentTrack = tracks[0]
    if (currentTrack) {
      return currentTrack.status
    }

    return null
  }

  render() {
    const listItems = this.props.tracks.map((track) =>
      <div key={track.id} className="flex bg-gray-200 w-screen px-4 py-2">
        <div className="flex-grow">
          <div>
            {track.song_name}
          </div>
          <div>
            {track.artist}
          </div>
        </div>
        <div className="flex-grow text-right">
          {track.user_name}
        </div>
      </div>
    );

    const currentState = this.queueState();

    return (
      <div>
        <div className='container mx-auto align-center text-center p-4'>
          <Controls
            logout={this.props.logout}
            play={this.props.play}
            pause={this.props.pause}
            skip={this.props.skip}
            queueState={currentState}
          />
        </div>

        <h3>Queue: </h3>

        {listItems}
      </div>
    )
  }
}

export default Queue;
