let fullBackendHost

const localStorageKey = 'mathisToken'

function backendHost() {
  if (fullBackendHost) {
    return fullBackendHost
  }

  let url = new URL(window.location.href)
  let search = new URLSearchParams(url.search);
  let origin = window.location.origin;
  let host
  if (search.has('backend')) {
    host = search.get('backend');
  } else if (/localhost/.test(origin)) {
    host = 'localhost:9090'
  } else if (/dev/.test(origin)) {
    host = 'mathis-development.herokuapp.com'
  } else {
    host = 'mathis-prod.herokuapp.com'
  }

  fullBackendHost = [window.location.protocol, '//', host].join('')
  return fullBackendHost
}

const FetchHelper = (url, options) => {
  var currentToken = localStorage.getItem(localStorageKey);
  return fetch(backendHost() + url, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken,
    },
    ...options,
  })
}

export default FetchHelper;
